<template>
  <multiselect
    v-model="selectedUsers"
    label="email"
    track-by="id"
    placeholder="Type to search"
    open-direction="bottom"
    :options="users"
    :multiple="true"
    :searchable="true"
    :loading="isLoading"
    :internal-search="false"
    :clear-on-select="false"
    :close-on-select="false"
    :options-limit="300"
    :limit="3"
    :max-height="600"
    :show-no-results="false"
    :hide-selected="true"
    @search-change="asyncFind"
  />
</template>

<script>
import axios from '@/libs/axios.js';

export default {
  name: 'UserSelect',
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      users: [],
    };
  },
  computed: {
    selectedUsers: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  created() {
    this.asyncFind();
  },
  methods: {
    async asyncFind(term = '') {
      this.isLoading = true;

      try {
        const { data: { data } } = await axios.get('/users/suggestion', { params: { term } });

        this.users = data;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <b-card
    no-body
    class="mb-0"
  >
    <b-card-body>
      <b-row>
        <b-col
          v-if="isAdmin"
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('user') }}</label>
          <UserSelect v-model="localUsers" />
        </b-col>
        <b-col
          cols="12"
          :md="isAdmin ? 3 : 6"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('country') }}</label>
          <CountrySelect v-model="localCountries" />
        </b-col>
        <b-col
          cols="12"
          :md="isAdmin ? 3 : 6"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('status') }}</label>
          <multiselect
            v-model="localStatuses"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :multiple="true"
            label="label"
            track-by="value"
            :options="statusesOptions"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import UserSelect from '@/views/apps/components/UserSelect.vue';
import {
  BCard, BCardBody, BCol, BRow,
} from 'bootstrap-vue';
import { dealStatuses } from '@/constants/deals.js';
import CountrySelect from '@/views/apps/components/CountrySelect.vue';

export default {
  name: 'TradingDealsFilter',
  components: {
    CountrySelect,
    BCard,
    BRow,
    BCol,
    BCardBody,
    UserSelect,
  },
  props: {
    users: {
      type: Array,
    },
    countries: {
      type: Array,
    },
    statuses: {
      type: Array,
    },
  },
  data() {
    return {
      statusesOptions: Object.entries(dealStatuses).map(([value, label]) => ({ value, label: label.name })),
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    localUsers: {
      get() {
        return this.users;
      },
      set(val) {
        this.$emit('update:users', val);
      },
    },
    localCountries: {
      get() {
        return this.countries;
      },
      set(val) {
        this.$emit('update:countries', val);
      },
    },
    localStatuses: {
      get() {
        return this.statuses;
      },
      set(val) {
        this.$emit('update:statuses', val);
      },
    },
  },
};
</script>

<style>

</style>

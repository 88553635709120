<template>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">
          {{ $t('tableDetails', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}
        </span>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="paginationMeta.total"
          :per-page="paginationMeta.per_page"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BPagination } from 'bootstrap-vue';

export default {
  name: 'FooterPagination',
  components: {
    BRow, BCol, BPagination,
  },
  props: {
    paginationMeta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.paginationMeta.current_page;
      },
      set(page) {
        this.$emit('input', page);
      },
    },
  },
};
</script>

<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body>
      <TradingDealsFilter
        :users.sync="filter.users"
        :countries.sync="filter.countries"
        :statuses.sync="filter.statuses"
      />
      <PerPageTable :per-page.sync="paginationMeta.per_page" />

      <b-table
        ref="refOfferListTable"
        :items="deals"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isLoading"
        show-empty
        empty-text="No matching records found"
        class="position-relative c-deals__table"
      >
        <!-- Loader -->
        <template #table-busy>
          <table-loader />
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <p class="mb-0">
            {{ data.item.id }}
          </p>
        </template>
        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            :variant="getDealStatus(data.item.status).variant"
            class="px-1 mb-0"
          >
            {{ getDealStatus(data.item.status).name }}
          </b-badge>
        </template>

        <!-- Column: Date -->
        <template #cell(date)="data">
          <p class="mb-0">
            {{ data.item.offer.created_at | formatDate }}
          </p>
          <p class="mb-0">
            {{ data.item.offer.valid_until | formatDate }}
          </p>
        </template>

        <!-- Column: Supplier -->
        <template #cell(supplier)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.offerInfo.buyer.avatar_url"
                :text="avatarText(data.item.offerInfo.buyer.name)"
                variant="light-primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.offerInfo.supplier.companyName }}
            </span>
            <small class="text-muted">
              {{ data.item.offerInfo.supplier.country }}
            </small>
          </b-media>
        </template>

        <!-- Column: Product -->
        <template #cell(product)="data">
          <b-media
            v-if="data.item.offerInfo.isProductsExist"
            class="product-column"
            vertical-align="center"
          >
            <template #aside>
              <b-img
                size="32"
                rounded
                style="width: 32px;"
                :src="data.item.offerInfo.productImage"
                variant="light-primary"
              />
            </template>
            <div class="d-flex">
              <h6
                class="text-nowrap product-column-name"
              >
                {{ data.item.offerInfo.productName }}
              </h6>
              <h6>
                {{ data.item.offerInfo.productCount }}
              </h6>
            </div>
            <small class="text-muted">
              {{ data.item.offerInfo.productIndex }}
            </small>
          </b-media>
        </template>

        <!-- Column: Price -->
        <template #cell(price)="data">
          <b-media vertical-align="center">
            <template
              v-if="isManager"
              #aside
            >
              <b-avatar
                :id="`offer-row-${data.item.id}`"
                size="32"
                :variant="`light-${resolveOfferStatusVariantAndIcon(data.item.offer.status).variant}`"
              >
                <feather-icon
                  :icon="resolveOfferStatusVariantAndIcon(data.item.offer.status).icon"
                />
              </b-avatar>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.offer.currency | currencySymbol }}{{ data.item.price }}
            </span>
            <small
              v-if="isManager"
              class="text-muted"
            >
              {{ resolveOfferStatusVariantAndIcon(data.item.offer.status).status }}
            </small>
          </b-media>
        </template>

        <!-- Column: Buyer -->
        <template #cell(buyer)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar_url"
                :text="avatarText(data.item.companyName)"
                variant="light-primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.companyName }}
            </span>
            <small class="text-muted">
              {{ data.item.country }}
            </small>
          </b-media>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.offer.avatar_url"
                :text="avatarText(data.item.offer.client.name)"
                :variant="`light-${resolveClientAvatarVariant(data.item.offer.offerStatus)}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.offer.client.name }}
            </span>
            <small class="text-muted">
              {{ data.item.offer.client.companyEmail }}
            </small>
          </b-media>
        </template>

        <!-- Column: Last Comment -->
        <template #cell(actionsRequired)="data">
          <ActionRequired :actions="data.item.required_actions[data.item.user_role]" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex justify-content-center align-items-center">
            <b-link
              :to="{ name: 'trading-deals-add', params: { id: data.item.id } }"
            >
              <feather-icon
                :id="`offer-row-${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
              />
            </b-link>
          </div>
        </template>
      </b-table>

      <FooterPagination
        v-model="paginationMeta.current_page"
        :pagination-meta="paginationMeta"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BMedia, BAvatar, BLink, BImg, BBadge,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import PerPageTable from '@/views/apps/components/PerPageTable.vue';
import TableLoader from '@/views/tables/TableLoader.vue';
import { getDealStatus } from '@/constants/deals.js';
import { getOfferInfo, resolveOfferStatusVariantAndIcon } from '@/utils/offerUtils.js';
import FooterPagination from '@/views/apps/components/FooterPagination.vue';
import TradingDealsFilter from '@/views/trading/trading-deals-list/TradingDealsFilter.vue';
import ActionRequired from '@/views/apps/components/ActionRequired.vue';

export default {
  components: {
    ActionRequired,
    TradingDealsFilter,
    FooterPagination,
    PerPageTable,
    TableLoader,
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BImg,
    BLink,
    BBadge,
  },
  data() {
    return {
      isLoading: false,
      deals: [],
      filter: {
        users: [],
        countries: [],
        statuses: [],
      },
      paginationMeta: {
        current_page: 1,
        per_page: 10,
        total: 0,
        from: 0,
        to: 0,
      },
      sortBy: 'id',
    };
  },
  computed: {
    isSupplier() {
      return this.$store.getters['profile/isSupplierOnly'];
    },
    isBuyer() {
      return this.$store.getters['profile/isBuyerOnly'];
    },
    isManager() {
      return this.$store.getters['profile/isAdmin'];
    },
    tableColumns() {
      if (this.isManager) {
        return [
          { key: 'id', label: '#', sortable: true },
          { key: 'status', label: this.$t('status'), sortable: true },
          { key: 'date', label: this.$t('dateTerm'), sortable: true },
          { key: 'supplier', label: this.$t('supplier') },
          { key: 'product', label: this.$t('product') },
          { key: 'price', label: this.$t('price'), sortable: true },
          { key: 'buyer', label: this.$t('buyer') },
          { key: 'actionsRequired', label: this.$t('actionsRequired'), sortable: true },
          { key: 'actions', label: this.$t('actions') },
        ];
      }
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'status', label: this.$t('status'), sortable: true },
        { key: 'date', label: this.$t('dateTerm'), sortable: true },
        { key: 'product', label: this.$t('product') },
        { key: 'price', label: this.$t('price'), sortable: true },
        { key: 'actionsRequired', label: this.$t('actionsRequired'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ];
    },
    dealsType() {
      return this.$route.params.param;
    },
  },
  watch: {
    'paginationMeta.per_page': 'fetchDeals',
    'paginationMeta.current_page': 'fetchDeals',
    dealsType: 'fetchDeals',
    filter: {
      deep: true,
      handler: 'fetchDeals',
    },
  },
  created() {
    this.fetchDeals();
  },
  methods: {
    avatarText,
    getDealStatus,
    resolveOfferStatusVariantAndIcon,
    dealsMapper(deal) {
      return {
        ...deal,
        offerInfo: getOfferInfo(deal.offer),
        companyName: deal.accepted_by?.company_name || '',
        avatar_url: deal.accepted_by?.profile?.avatar_url || '',
        country: deal.accepted_by?.profile?.country.name || '',
      };
    },
    async fetchDeals() {
      const separatedRouteParams = this.dealsType.split('-');
      const paramsToSend = {
        page: this.paginationMeta.current_page,
        per_page: this.paginationMeta.per_page,
        user_ids: this.filter.users.map(({ id }) => id),
        statuses: this.filter.statuses.map(({ value }) => value),
        country_ids: this.filter.countries.map(({ id }) => id),
      };
      let initDealsType = '';
      if (separatedRouteParams.length > 1) {
        const [dealsType, role] = this.dealsType.split('-');
        initDealsType = dealsType;
        const isRoleExist = role === 'buyer' || role === 'supplier';
        if (isRoleExist) {
          paramsToSend.role = role;
        }
      }

      this.isLoading = true;

      const { data: { data: deals, meta } } = await this.$store.dispatch('deal/fetchList', {
        type: initDealsType === 'own' ? 'own' : '',
        params: paramsToSend,
      }).finally(() => {
        this.isLoading = false;
      });
      this.deals = deals.map(this.dealsMapper);
      this.paginationMeta = meta;
    },
  },
};
</script>

<style lang="scss">
.c-deals__table {
  td {
    padding: 0.75rem;
  }
  & td:nth-child(7) {
    padding: 0.75rem 0.6rem;
  }
  & td:nth-child(8) {
    white-space: nowrap;
    padding: 0.5rem;
  }
  .table th {
    padding: 1rem;
  }
}
@import 'src/@core/scss/vue/libs/vue-select.scss';
.product-column {
  max-width: 300px;
  .media-body {
    max-width: 100%;
  }
  &-name {
    max-width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>

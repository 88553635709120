<template>
  <div class="mx-2 mb-2">
    <!-- Table Top -->
    <b-row>
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>{{ $t('showEntriesQty') }}</label>
        <v-select
          v-model="perPageLocal"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block ml-50 mr-1"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { BRow, BCol } from 'bootstrap-vue';

export default {
  name: 'PerPageTable',
  components: {
    BRow,
    BCol,
    vSelect,
  },
  props: {
    perPage: {
      type: Number,
    },
  },
  data() {
    return {
      perPageOptions: [10, 25, 50, 100],
    };
  },
  computed: {
    perPageLocal: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.$emit('update:perPage', value);
      },
    },
  },
};
</script>
